<template>
<div class="CommonWidth">

  <div class="wol">
    <div class="image">
        <img src="@/assets/careers2.png" />
    </div>
    <div class="title">
      <div class="content">是时候大展身手了! 快来看看有没有合适你的岗位吧</div>
    </div>
  </div>

  <div class="Careers">
        <el-row :gutter="20">

          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="selete">
              <div class="title">
                通过招聘平台联系我们并了解正在招聘的职位
                <div class="text">通过平台获得最新招聘职位信息</div>
              </div>

              <div class="hrefbox"><a href="https://www.zhipin.com/gongsi/ea4084341de320251nd509y1EFY~.html" target="_blank" >打开BOSS直聘</a></div>

            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="selete">

              <div class="title">
                发送邮件至我们的邮箱，获得联系
                <div class="text">通过邮箱取得联系和招聘职位信息</div>
              </div>
              <div class="hrefbox">4366153@qq.com</div>

            </div>
          </el-col>

        </el-row>
  </div>


  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Careers',
  components: {
  }
}
</script>
<style lang="scss" scoped>

  .wol{
    width: 100%;
    padding-top: 130px;
    padding-bottom: 130px;
    display: flow-root;
    .image{
      float: left;
      display: flex; justify-content: right;
      // width: 100%;
      width: 38%;
      img{max-width: 400px;}
    }
    .title{
      float: right;
      // line-height: 400px;
      margin-top: 110px;
      font-size: 48px;
      width: 58%;
      text-align: left;
      .content{
        width: 70%;
      }
    }
  }

  .selete{
    margin-bottom: 100px;
    padding: 20px;
    // width: 100%;
    height: 180px;
    // border-radius: 15px;
    // border: 1px solid #cecece;
    // box-shadow: 0px 7px 7px hsl(0, 0%, 86%);
    border: 1px solid #ebebeb;
    .text{
      font-size: 14px;
      margin-top: 15px;
      height: 85px;
    }
  .title{
    font-size: 24px;
    height: 140px;
  }
  }
  .hrefbox{
    background-color: #385cff;
    color: #ffffff;
    width: 130px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    a{color: #ffffff;}
  }

  @media screen and (max-width: 850px) {
  .wol{
    width: 100%;
    padding-top: 130px;
    padding-bottom: 130px;
    display: flow-root;
    .image{
      float: left;
      display: flex; justify-content: right;
      // width: 100%;
      width:100%;
      img{max-width: 400px;}
    }
    .title{
      float: right;
      // line-height: 400px;
      margin-top: 110px;
      font-size: 32px;
      width: 100%;
      text-align: center;
      .content{
        width: 100%;
      }
    }
  }
  }
</style>